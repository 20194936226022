import axios from "axios";

/**
 * que tiene como objetivo agrupar los métodos para recuperar los
 * datos de
 */
class FlickrAPI {
  /**
   * @constructor
   */
  constructor() {
    this.args = {
      format: "json",
      nojsoncallback: 1,
    };

    this.api = axios.create({
      baseURL: "https://www.flickr.com/services/rest",
    });
  }

  setArgs(args) {
    this.args = { ...this.args, ...args };
  }
  /**
   * Se encarga de recuperar la lista de albumes de flickr
   */
  albumes() {
    return this.api.get("/", {
      params: {
        method: "flickr.photosets.getList",
        ...this.args,
      },
    });
  }

  /**
   * Se encarga de recuperar la lista de fotos de un album de flickr a
   * partir de su identificador.
   */
  fotos() {
    return this.api.get("/", {
      params: {
        method: "flickr.photosets.getPhotos",
        ...this.args,
      },
    });
  }
}

export default new FlickrAPI();
