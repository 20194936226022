import React, { useState, useEffect } from "react";
import {
  VerticalTimeline,
  VerticalTimelineElement,
} from "react-vertical-timeline-component";
import strapiAPI from "../../../api/strapi-api";
import { Collapsible } from "../../3party/Collapsible";
import { Fa } from "../../3party/Fa";
import { Pagina } from "../../cms/Pagina";
import { Error } from "../error/Message";
import { Loading } from "../loading/Loading";
import { ListaDocumentos } from "./ListaDocumentos";

export function DocumentosContent({ documentos }) {
  const [data, setData] = useState([]);
  const [plain, setPlain] = useState({ children: [] });
  const [loading, setLoading] = useState(true);
  const [selected, setSelected] = useState(null);
  const [error, setError] = useState(false);

  /**
   * Se encaga de manejar la actualización del componente
   */
  useEffect(() => {
    if (!documentos || documentos.length === 0) {
      getDocumentos();
    } else {
      var tmp = procesarAnhos(documentos);
      var docs = procesarDocumentos(documentos);
      setPlain(docs);
      setData(tmp);
      setLoading(false);
    }
  }, []);

  const getDocument = (item) => {
    if (item == null || item.children == null || item.children.length === 0) {
      return [item];
    }

    return item.children.map((doc) => {
      if (doc == null || doc.children == null || doc.children.length === 0) {
        return doc;
      }
      return getDocument(doc);
    });
  };

  const procesarDocumentos = (data) => {
    var plainGroup = { children: [] };
    data.map((item) => {
      var plainCnt = 0;

      item.items.map((el) => {
        if (el.children.length === 0) {
          plainCnt++;
        }
      });

      if (plainCnt > 0) {
        item.children = item.items.filter((e) => {
          return e.children.length === 0;
        });
        item.title = item.name;
        plainGroup.children.push(item);
      }
    });

    return plainGroup;
  };

  const procesarAnhos = (data) => {
    var newData = [];
    data.map((item) => {
      var map = {};
      let newItem = {
        id: item.id,
        nombre: item.name,
        descripcion: item.description,
        anhos: [],
      };
      var values = {};
      item.items.map((el) => {
        el.children.map((child) => {
          var docs = getDocument(child);
          if (!docs) {
            return;
          }
          docs.map((doc) => {
            var d = new Date(doc.url);
            var year = d.getFullYear();
            var parent = doc.parent;
            map[year] = map[year] ? map[year] : {};
            var idx = map[year][parent.id];
            if (typeof idx === "undefined") {
              values[year] = values[year] ? values[year] : [];
              values[year].push({
                id: parent.id,
                title: parent.title,
                children: [],
              });
              idx = values[year].length - 1;
              map[year][parent.id] = idx;
            }
            values[year][idx].children.push(doc);
          });
        });
      });

      for (var key in values) {
        newItem.anhos.push({
          id: `${item.id}-${key}`,
          parent: item.id,
          anho: key,
          children: values[key],
        });
      }
      newData.push(newItem);
    });

    return newData;
  };

  /**
   * Se encarga de recuperar la lista de noticias para renderizar.
   */
  const getDocumentos = () => {
    setLoading(true);
    strapiAPI
      .listDocumentos({ page_gt: "0" })
      .then((response) => {
        var all = response.data.filter((item) => {
          return item.published_at != null;
        });
        var tmp = procesarAnhos(all);
        var docs = procesarDocumentos(all);
        setPlain(docs);
        setData(tmp);
      })
      .catch((err) => {
        setError(true);
      })
      .finally((resp) => {
        setLoading(false);
      });
  };

  const onCollapsible = (status) => {
    if (status === "close") {
      setSelected(null);
    }
  };

  /**
   * Renderiza el componente
   */
  return (
    <div className="">
      {loading && <Loading />}
      {!loading &&
        !error &&
        data
          .filter((item) => {
            return item.anhos.length > 0;
          })
          .map(function (item, idx) {
            return (
              <Collapsible
                title={item.nombre}
                onClick={onCollapsible}
                open={idx === 0}
              >
                <div className="columns is-multiline timeline">
                  <div className="column is-12">
                    <p>{item.descripcion}</p>
                  </div>
                  <div className="column is-6 timeline-content">
                    <VerticalTimeline>
                      {item.anhos.map((el, index) => {
                        return (
                          <VerticalTimelineElement
                            key={index}
                            onTimelineElementClick={() => {
                              setSelected(el);
                            }}
                            className={`timeline-el-${index} ${
                              selected && el.id === selected.id
                                ? "is-active"
                                : ""
                            }`}
                            icon={<Fa icon="file-download" simple />}
                          >
                            <strong>{el.anho}</strong>
                          </VerticalTimelineElement>
                        );
                      })}
                    </VerticalTimeline>
                  </div>
                  {selected && selected.parent === item.id && (
                    <ListaDocumentos data={selected}></ListaDocumentos>
                  )}
                  {!selected && (
                    <div className="column">
                      <article className="message is-info">
                        <div className="message-body">
                          <Fa icon="info"></Fa> <strong>Obs:</strong> Los
                          documentos se encuentran agrupados por años, para
                          visualizar los documentos correspondientes a un año
                          especifico primero debe hacer 'Click' en el año de
                          interes.
                        </div>
                      </article>
                    </div>
                  )}
                </div>
              </Collapsible>
            );
          })}
      {!loading && !error && plain.children.length > 0 && (
        <>
          {" "}
          <br />
        </>
      )}
      {!loading && !error && (
        <div className="timeline">
          <ListaDocumentos data={plain}></ListaDocumentos>
        </div>
      )}
      {!loading && error && (
        <Error>No se pudo recuperar la lista de documentos.</Error>
      )}
    </div>
  );
}
export function Documentos(props) {
  return (
    <Pagina path="documentos-e-informes">
      <DocumentosContent></DocumentosContent>
    </Pagina>
  );
}
