import React, { useState, useEffect } from "react";
import { Fa } from "../../3party/Fa";
import { DateFormat } from "../../hooks/FormatterHooks";
import { VideoCard } from "./VideoBox";

export function VideoBoxes({ data }) {
  const [destacado, setDestacado] = useState({});
  const [loading, setLoading] = useState(true);

  /**
   * Se encaga de manejar la actualización del componente
   */
  useEffect(() => {
    if (data.length > 0 && data[0].items.length > 0) {
      data[0].items.sort((a, b) => {
        return a.order - b.order;
      });
      setDestacado(data[0].items[0]);
    }
  }, []);

  return (
    <div className="video-box">
      <h3 className="title is-3">Videos</h3>
      <div className="columns">
        {destacado && (
          <div className="column is-7">
            <iframe
              width="100%"
              height="500px"
              src={destacado.url}
              frameborder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowfullscreen
            ></iframe>
          </div>
        )}
        <div className="column">
          <div className="card">
            <aside className="menu">
              <ul className="menu-list">
                {data &&
                  data.length > 0 &&
                  data.map((el) => {
                    return el.items
                      .sort((a, b) => {
                        return a.order - b.order;
                      })
                      .map((item) => {
                        return (
                          <li
                            onClick={(evt) => {
                              setDestacado(item);
                            }}
                          >
                            <a
                              className={
                                item.id == destacado.id ? "is-active" : ""
                              }
                            >
                              <VideoCard data={item}></VideoCard>
                            </a>
                          </li>
                        );
                      });
                  })}
              </ul>
            </aside>
          </div>
        </div>
      </div>
    </div>
  );
}
