import React from "react";
import { Fa } from "../../3party/Fa";
import noImage from "../../../images/no-image.svg";
import { DateFormat } from "../../hooks/FormatterHooks";
import { Link } from "react-router-dom";

export function NewsCard({ noticia }) {
  const newImg =
    noticia?.media.length > 0 ? noticia.media[0] : { url: noImage };
  return (
    <div className="column is-12">
      <div className="columns news-list">
        <div className="column is-4">
          <figure className="image is-4by3">
            <img
              className="has-background-light"
              loading="lazy"
              src={`${newImg.url}`}
              alt={noticia.title}
            />
          </figure>
        </div>

        <div className="column is-8">
          <div className="content">
            <p className="title is-4">{noticia.title}</p>
            <p className="has-text-justified">{noticia.excerpt}</p>
          </div>
          <hr />
          <span className="button is-small is-white is-time">
            <Fa icon="clock"></Fa>
            <DateFormat>{noticia.date}</DateFormat>
          </span>
          <Link
            className="button is-text is-small"
            to={`/noticias/${noticia.slug}`}
          >
            + Leer Más
          </Link>
        </div>
      </div>
    </div>
  );
}
