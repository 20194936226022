import React from "react";

export function Fa(props) {
  let prefix = props.solid ? "fab" : "fas";
  let space= !props.simple?<span>&nbsp;</span>: "";
  return (
    <React.Fragment>
      <i className={` ${prefix} fa-${props.icon}`}></i>{space}
    </React.Fragment>
  );
}
