import React from "react";

export function FieldControl({
  label,
  type,
  onChange,
  value,
  error,
  errorMessage,
}) {
  return (
    <div className="field">
      <label className="label">{label}</label>
      <div className="control">
        {type !== "textarea" && (
          <input
            className={`input ${error ? "is-danger" : ""}`}
            type={type}
            onChange={onChange}
            value={value}
          ></input>
        )}
        {type === "textarea" && (
          <textarea
            className="textarea"
            onChange={onChange}
            value={value}
          ></textarea>
        )}
      </div>
      {error && <p className="help is-danger">{errorMessage}</p>}
    </div>
  );
}

export function MediaObject({ image, title, children }) {
  return (
    <article className="media">
      {image && (
        <figure className="media-left">
          <p className="image is-64x64">
            <img className="has-background-light" loading="lazy" src={image} />
          </p>
        </figure>
      )}
      <div className="media-content">
        <div className="content">
          <p>
            <strong>{title}</strong>
            <br />
            <span className="is-size-7">{children}</span>
          </p>
        </div>
      </div>
    </article>
  );
}

export function Card({ image, title, children }) {
  return (
    <div className="card">
      <div className="card-image">
        <figure className="image ">
          <img src={image} alt={title} />
        </figure>
      </div>
      <div className="card-content">{children}</div>
    </div>
  );
}
