import React, { useState, useEffect } from "react";
import { Link, useHistory } from "react-router-dom";
import { Fa } from "../3party/Fa";
import strapiAPI from "../../api/strapi-api";

export default function Navbar() {
  const [expanded, setExpanded] = useState(false);
  const [subMenu, setSubMenu] = useState(false);
  const [currentSubItem, setCurrentSubItem] = useState(null);
  const [routes, setRoutes] = useState([]);
  const [currentItem, setCurrentItem] = useState({});
  const [loading, setLoading] = useState(true);
  const filter = new URLSearchParams(window.location.search);
  const [search, setSearch] = useState(filter.get("_q"));
  const history = useHistory();

  /**
   * Se encarga de recuperar la lista de noticias para renderizar.
   */
  const getMenus = () => {
    setLoading(true);
    strapiAPI
      .listMenus("menu-principal")
      .then((response) => {
        let menu = response.data[0];
        menu = menu.items.filter((item) => {
          return item.published_at != null;
        });
        menu.sort(compareFunc).forEach((item) => {
          if (item.children && item.children.length) {
            item.children.sort(compareFunc).forEach((item) => {
              if (item.children && item.children.length) {
                item.children.sort(compareFunc);
              }
            });
          }
        });
        setRoutes(menu);
      })
      .catch((err) => {
        console.error(err);
      })
      .finally((resp) => {
        setLoading(false);
      });
  };

  const compareFunc = (item1, item2) => {
    if (item1.order < item2.order) return -1;
    else if (item1.order > item2.order) return 1;
    else return 0;
  };

  /**
   * Se encaga de manejar la actualización del componente
   */
  useEffect(() => {
    getMenus();
  }, []);

  /**
   * Se encarga de setear los parametros para visualizar el menú
   *
   * @param {*} item
   * @param {*} show
   */
  const showSubMenu = (item, show) => {
    if (item.children && item.children.length) {
      setSubMenu(show);
    }
    setCurrentItem(item);
  };

  const handleSearch = (event) => {
    event.preventDefault();
    let searchText = search;
    setSearch("");
    setExpanded(false);
    history.push(`/busqueda?_q=${searchText}`);
  };

  const renderDropdown = (routes) => {
    return routes.map((item) => {
      const hasChildren = item.children && item.children.length > 0;
      return (
        <React.Fragment>
          {!hasChildren && (
            <Link className="navbar-item" to={item.url}>
              {item.title}
            </Link>
          )}
          {hasChildren && (
            <div className="navbar-item is-hoverable has-dropdown">
              <a className="navbar-link">{item.title}</a>
              <div className="navbar-dropdown">
                {item.children.map((el) => {
                  return (
                    <Link className="navbar-item" to={el.url}>
                      {el.title}
                    </Link>
                  );
                })}
              </div>
            </div>
          )}
        </React.Fragment>
      );
    });
  };

  const renderMenuItems = (main, items) => {
    return (
      <ul className="is-size-6">
        {items.map((el) => {
          if (el.visible == false) {
            return;
          }
          return (
            <li key={el.id}>
              {!el.url.includes("http") && (
                <Link
                  to={el.url}
                  onClick={() => {
                    setExpanded(false);
                    showSubMenu(main, false);
                  }}
                >
                  {el.title}
                </Link>
              )}
              {el.url.includes("http") && (
                <a
                  href={el.url}
                  target="_blank"
                  onClick={() => {
                    setExpanded(false);
                    showSubMenu(main, false);
                  }}
                  key={el.id}
                >
                  {el.title}
                </a>
              )}
            </li>
          );
        })}
      </ul>
    );
  };

  const hasChildren = (item) => {
    return (
      item &&
      item.children &&
      item.children[0] &&
      item.children[0].children &&
      item.children[0].children.length > 0
    );
  };
  const renderSubMenu = () => {
    return (
      <nav
        className={`menu ${subMenu ? "visible" : ""} is-hidden-touch`}
        onMouseEnter={() => setSubMenu(true)}
        onMouseLeave={() => setSubMenu(false)}
      >
        <nav className="menu-section">
          <div className="menu-content">
            {!loading &&
              routes.map((item) => {
                if (!item.children) {
                  return;
                }
                return (
                  <div
                    key={item.id}
                    className={`menu-item ${
                      currentItem.title == item.title
                        ? "is-visible colummns is-multiline"
                        : "is-invisible"
                    }`}
                  >
                    {!hasChildren(item) && (
                      <div key={item.id} className="column is-4">
                        {renderMenuItems(item, item.children)}
                      </div>
                    )}
                    {hasChildren(item) &&
                      item.children.map((section) => {
                        return (
                          <div key={section.id} className="column is-4">
                            <h4>{section.title}</h4>
                            {renderMenuItems(item, section.children)}
                          </div>
                        );
                      })}
                  </div>
                );
              })}
          </div>
        </nav>
      </nav>
    );
  };

  const renderSubmenuMobile = (item) => {
    return (
      <div
        className={`navbar-dropdown ${
          !subMenu ? "is-hidden" : ""
        } is-hidden-desktop`}
      >
        {item.children.map((section) => (
          <div key={section.id} className="navbar-item">
            <div
              className={`navbar-link ${expanded ? "has-text-white" : ""} ${
                !currentSubItem && currentItem.title === section.title
                  ? "is-active"
                  : ""
              }`}
              onClick={() => {
                showSubMenu(section, subMenu);
                setCurrentSubItem(null);
              }}
            >
              <span>&nbsp;&nbsp;</span>
              {section.title}
            </div>
            <div
              className={`navbar-dropdown ${
                currentItem.title !== section.title ? "is-hidden" : ""
              }`}
            >
              {section.children.map((element) => (
                <Link
                  key={element.id}
                  className={`navbar-item has-text-white ${
                    currentSubItem && currentSubItem.title === element.title
                      ? "is-active"
                      : ""
                  }`}
                  to={element.url}
                  onClick={() => {
                    setExpanded(false);
                    setCurrentSubItem(element);
                  }}
                >
                  {element.title}
                </Link>
              ))}
            </div>
          </div>
        ))}
      </div>
    );
  };

  const renderForm = () => {
    return (
      <form className="buscador" onSubmit={handleSearch}>
        <div className="field has-addons">
          <div className="control">
            <input
              className="input"
              type="text"
              placeholder="Buscar"
              value={search}
              onChange={(event) => setSearch(event.target.value)}
            />
          </div>
          <div className="control">
            <button className="button" type="submit">
              <Fa icon="search"></Fa>
            </button>
          </div>
        </div>
      </form>
    );
  };

  return (
    <section className="main-menu" onMouseLeave={() => showSubMenu({}, false)}>
      <nav
        className="navbar is-primary"
        role="navigation"
        aria-label="main navigation"
      >
        <div className="navbar-brand">
          <a
            role="button"
            className={`navbar-burger burger ${expanded ? "is-active" : ""}`}
            aria-label="menu"
            aria-expanded={expanded}
            data-target="navbarBasicExample"
            onClick={() => setExpanded(!expanded)}
          >
            <span aria-hidden="true"></span>
            <span aria-hidden="true"></span>
            <span aria-hidden="true"></span>
          </a>
        </div>

        <div
          className={`navbar-menu ${
            expanded ? "is-active" : ""
          } pt-0 has-background-primary`}
        >
          <div className="navbar-start">
            <Link className="navbar-item" to="/">
              <Fa icon="home" />{" "}
            </Link>
            {renderDropdown(routes)}
          </div>
          <div className="navbar-end">
            <div className="navbar-item">{renderForm()}</div>
          </div>
        </div>
      </nav>
      {renderSubMenu()}
    </section>
  );
}
