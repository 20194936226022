import React, { useState } from "react";
import OwlCarousel from "react-owl-carousel2";
import { Link } from "react-router-dom";

/**
 * Se encarga renderizar el carusel de imagenes de los baners.
 *
 * @param {*} param0
 */
export const Banner = ({ data }) => {
  const options = {
    items: 1,
    loop: true,
    autoplay: true,
    autoplayTimeout: 5000,
    autoHeight: true,
    nav: true,
    navText: [
      '<i class="fas fa-caret-left"></i>',
      '<i class="fas fa-caret-right"></i>',
    ],
  };

  const [loaded, setLoaded] = useState(false);

  return (
    <div className="banner">
      {data && data.length > 0 && (
        <OwlCarousel options={options}>
          {data.map((banner) => {
            return banner.items
              .sort((a, b) => {
                return a.order - b.order;
              })
              .map((item) => {
                if (!item.content && !item.title) {
                  return (
                    <Link
                      to={item.url || "#"}
                      key={item.id}
                      className="banner-content is-desktop"
                    >
                      <img
                        className="has-background-light"
                        style={loaded ? {} : { display: "none" }}
                        alt={item.title}
                        src={`/cms${item.media.url}`}
                        onLoad={() => setLoaded(true)}
                      />
                    </Link>
                  );
                }

                return (
                  <div
                    key={item.id}
                    className="columns banner-content is-desktop"
                  >
                    <div className="column is-5-desktop is-full-tablet">
                      <img
                        style={loaded ? {} : { display: "none" }}
                        alt={item.title}
                        src={`/cms/${item.media.url}`}
                        onLoad={() => setLoaded(true)}
                      />
                    </div>
                    <div className="column is-7-desktop is-full-tablet is-full-tablet">
                      {item.title && (
                        <h2 className="title is-size-4 has-text-centered">
                          {item.title}
                        </h2>
                      )}
                      {item.content && (
                        <p
                          className="legend"
                          dangerouslySetInnerHTML={{ __html: item.content }}
                        ></p>
                      )}
                      {item.url && (
                        <p className="mas-info">
                          Para más información click{" "}
                          <Link to={item.url}>aquí</Link>.
                        </p>
                      )}
                    </div>
                  </div>
                );
              });
          })}
        </OwlCarousel>
      )}
    </div>
  );
};
