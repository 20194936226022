import React, { useState, useEffect, useContext } from "react";
import { useParams, Link } from "react-router-dom";
import strapiAPI from "../../api/strapi-api";
import Markdown from "react-markdown";
import { Banner } from "./Banner";
import { DocumentosContent } from "../pagina/documentos/Documentos";
import { GraficoPagina } from "./GraficoPagina";
import { Graficos } from "./Graficos";
import { ViewsContext } from "../../context/ViewsContext";
import { MenuPagina } from "./MenuPagina";
import { Loading } from "../pagina/loading/Loading";
import { NotFound } from "../pagina/error/NotFound";
import { Error } from "../pagina/error/Message";
import { VideoBoxes } from "../pagina/home/VideoBoxes";
import { ListaPagina } from "./ListaPagina";
/**
 * Componente que corresponde al contenido de la página
 * @param {*} props
 */
export function Content({
  pagina,
  menus,
  videos,
  banners,
  documentos,
  graficos,
  listas,
  hide,
}) {
  let pageContent = <div></div>;
  if (documentos.length > 0 && hide !== "Documentos") {
    pageContent = (
      <DocumentosContent documentos={documentos}></DocumentosContent>
    );
  }
  return (
    <>
      <Banner data={banners}></Banner>
      <h1 className="title">{pagina.title}</h1>
      <hr />
      {menus.length > 0 && (
        <>
          <MenuPagina menu={menus[0]} />
          <br />
        </>
      )}
      <div className="pagina-content">
        <Markdown
          className="pagina-md"
          escapeHtml={false}
          source={pagina.content}
        />
        {graficos.length >= 1 && graficos[0].items.length > 1 && (
          <Graficos data={graficos}></Graficos>
        )}
        {graficos.length == 1 && graficos[0].items.length == 1 && (
          <GraficoPagina data={graficos}></GraficoPagina>
        )}
        {videos.length > 0 && <VideoBoxes data={videos}></VideoBoxes>}
        {listas.length > 0 && <ListaPagina data={listas[0]}></ListaPagina>}
      </div>
      {pageContent}
    </>
  );
}
/**
 * Componente que corresponde a la representación de una página.
 * @param {*} props
 */
export function Pagina(props) {
  const [data, setData] = useState({});
  const [banners, setBanners] = useState([]);
  const [menus, setMenus] = useState([]);
  const [documentos, setDocumentos] = useState([]);
  const [videos, setVideos] = useState([]);
  const [listas, setListas] = useState([]);
  const [otros, setOtros] = useState([]);
  const [graficos, setGraficos] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const { codigo } = useParams();
  const { dispatch } = useContext(ViewsContext);

  /**
   * Este componente se encarga de renderizar las listas asociadas
   * a las páginas y separar según su tipo.
   *
   * @param {*} data
   */
  const procesarListas = (data) => {
    data.lists = data.lists ? data.lists : [];
    let map = {
      Banners: [],
      Menus: [],
      Documentos: [],
      Videos: [],
      Listas: [],
      Graficos: [],
      Otros: [],
    };
    data.lists.map((list) => {
      if (!map[list.type]) {
        map[list.type] = [];
      }
      map[list.type].push(list);
    });
    setBanners(map["Banners"]);
    setMenus(map["Menus"]);
    setDocumentos(map["Documentos"]);
    setVideos(map["Videos"]);
    setListas(map["Listas"]);
    setGraficos(map["Graficos"]);
    setOtros(map["Otros"]);
  };

  /**
   * Recupera la página actual
   */
  const getPagina = () => {
    let path = props.path ? props.path : codigo;
    setLoading(true);
    strapiAPI
      .getPagina(path)
      .then((response) => {
        let pag = null;
        if (response.data.length > 0 && response.data[0]) {
          pag = response.data[0];
          dispatch({
            type: "UPDATE_VIEWS",
            payload: pag.view,
          });
          setData(pag);
          procesarListas(pag);
        }
      })
      .catch((error) => {
        setError(true);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  /**
   * Se encaga de manejar la actualización del componente
   */
  useEffect(() => {
    getPagina();
  }, [codigo]);

  /**
   * Este método se encarga de propagar los props a los componentes hijos
   * directos de este componente.
   */
  const propagateProps = () => {
    const childrenWithProps = React.Children.map(props.children, (child) => {
      // checking isValidElement is the safe way and avoids a typescript error too
      const props = {
        pagina: data,
        banners: banners,
        menus: menus,
        documentos: documentos,
        videos: videos,
        graficos: graficos,
        listas: listas,
      };
      if (React.isValidElement(child)) {
        return React.cloneElement(child, props);
      }
      return child;
    });

    return childrenWithProps;
  };

  /**
   * Se encarga de renderizar el componente
   */
  return (
    <React.Fragment>
      <div
        className={
          typeof props.content == "undefined" ? "container is-fluid pagina" : ""
        }
      >
        {loading && <Loading />}
        {!loading &&
          !error &&
          data &&
          data.content &&
          data.content.length > 0 &&
          typeof props.content == "undefined" && (
            <Content
              pagina={data}
              banners={banners}
              menus={menus}
              documentos={documentos}
              videos={videos}
              listas={listas}
              graficos={graficos}
              otros={otros}
              hide={props.hide}
            ></Content>
          )}
        {!loading && !error && data && propagateProps()}
        {!loading && !error && (!data || !data.content) && <NotFound />}
        {!loading && error && (
          <Error>No se pudo obtener la información de la página.</Error>
        )}
      </div>
    </React.Fragment>
  );
}
