import React, { useState, createContext, useEffect } from "react";
import flickrAPI from "../api/flickr-api";
import strapiAPI from "../api/strapi-api";
import { Loading } from "../components/pagina/loading/Loading";

export const ParametersContext = createContext();

export const ParametersProvider = (props) => {
  const [parameters, setParameters] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    strapiAPI
      .listParameters()
      .then((response) => {
        let parametros = response.data.map((item) => ({
          key: item.key,
          value: item.value,
          enabled: item.enabled,
        }));

        flickrAPI.setArgs({
          api_key: getParameterByKey("FLICKR_API_KEY", parametros).value,
          user_id: getParameterByKey("FLICKR_USER_ID", parametros).value,
          photoset_id: getParameterByKey("FLICKR_PHOTOSET_ID", parametros)
            .value,
        });

        setParameters(parametros);
      })
      .catch((error) => console.error(error.message))
      .finally(() => setLoading(false));
  }, []);

  const getParameterByKey = (paramKey, source = parameters) => {
    const parameter = source.find((item) => item.key === paramKey);
    return parameter;
  };

  return (
    <ParametersContext.Provider
      value={{ getParameterByKey, parameters, loading }}
    >
      {loading && <Loading />}
      {!loading && props.children}
    </ParametersContext.Provider>
  );
};
