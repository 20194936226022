import React, { useEffect, useState } from "react";
import $ from "jquery";
import ReactMarkdown from "react-markdown";

export function GraficoPagina({ data }) {
  window.resizeIframe = function (obj) {
    // puede inr entre comas los valores de los items especiales
    var customSelectors = "#js-plotly-tester, body, #root,.scroll-y";
    // si ya existe un interval se elimina para evitar solapamiento
    if (window.iframepid) {
      clearInterval(window.iframepid);
    }
    if (obj == null || obj.contentWindow == null) {
      return;
    }
    //se añade la funcion de redimensionamiento
    window.iframepid = setInterval(() => {
      try {
        if (obj == null || obj.contentWindow == null) {
          return;
        }
        //se obtiene el tamaño maximo de la pantalla.
        var maxHei = window.screen.availHeight;
        //se recupera el tamaño de iframe
        let size = obj.contentWindow.document.documentElement.scrollHeight;
        // se verifica y se añade un minimo.
        let h = size < 400 && size ? 400 : size;
        //se recupera y verifica si existe el target especial
        var $target =
          obj.contentWindow.document.querySelectorAll(customSelectors);
        if ($target) {
          var maxTargetHei = 0;
          $target.forEach(function (e) {
            var curr = $(e).height();
            maxTargetHei = curr > maxTargetHei ? curr : maxTargetHei;
          });
          //se calcula el tamaño
          var th = maxTargetHei;
          h = h < th ? th : h;
        }
        //se verifica que no supuere el maximo de la pantalla.
        h = h >= maxHei ? h : maxHei;
        //se setea el tamaño final.
        obj.style.height = h + "px";
      } catch (error) {
        console.error(
          "Ocurrió un error al tratar de redimensionar el iframe",
          error
        );
      }
    }, 500);
  };

  return data.map((el) => {
    return (
      <div>
        {el.items.map((item) => {
          return (
            <ReactMarkdown
              escapeHtml={false}
              source={`<iframe src="${item.url}" frameborder="0" scrolling="no" onload="resizeIframe(this)" />`}
            />
          );
        })}
      </div>
    );
  });
}
