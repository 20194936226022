import React from "react";
import { Link } from "react-router-dom";
import { Fa } from "../../3party/Fa";
import noImage from "../../../images/no-image.svg";
import { DateFormat } from "../../hooks/FormatterHooks";

export function NewsBox({ noticia }) {
  const newImg =
    noticia?.media.length > 0 ? noticia.media[0] : { url: noImage };

  const truncateText = (text) => {
    if (text.length > 250) {
      return text.substr(0, 250) + "...";
    }
    return text;
  };

  return (
    <div className="column">
      <div className="news-box">
        <div className="news-box-image">
          <figure className="image is-4by3">
            <img
              className="has-background-light"
              loading="lazy"
              src={`${newImg.url}`}
              alt={noticia.title}
            />
          </figure>
        </div>
        <div className="news-box-content">
          <div className="media">
            <div className="media-content">
              <p className="title is-6">{noticia.title}</p>
            </div>
          </div>
          <hr />
          <div className="content">
            <p className="news-resume">{noticia.excerpt}</p>
            <div className="has-text-centered"></div>
          </div>
        </div>
        <footer className="card-footer is-size-7">
          <p className="card-footer-item">
            <span>
              <Fa icon="clock"></Fa>
              <DateFormat>{noticia.date}</DateFormat>
            </span>
          </p>
          <p className="card-footer-item">
            <Link
              className="button is-text is-small"
              to={`/noticias/${noticia.slug}`}
            >
              + Leer Más
            </Link>
          </p>
        </footer>
      </div>
    </div>
  );
}
