import React, { useEffect, useState } from "react";
import { useLocation, Link, useParams } from "react-router-dom";
import strapiAPI from "../../../api/strapi-api";
import Markdown from "react-markdown";
import { Loading } from "../loading/Loading";
import { Pagina } from "../../cms/Pagina";
import { Fa } from "../../3party/Fa";
import { Message, Error } from "../error/Message";

function Busqueda(props) {
  const query = useLocation().search;
  const filter = new URLSearchParams(query);
  const [loading, setLoading] = useState(false);
  const [noticias, setNoticias] = useState([]);
  const [paginas, setPaginas] = useState([]);
  const [showing, setShowing] = useState("paginas");
  const [error, setError] = useState(false);

  const truncateText = (text) => {
    const limit = 400;
    if (text.length > limit) {
      return text.substr(0, limit) + "...";
    }
    return text;
  };

  const getSearchResults = async () => {
    setLoading(true);

    try {
      const noticiasResponse = await strapiAPI.listNoticias(query);
      setNoticias(noticiasResponse.data);

      const paginasResponse = await strapiAPI.listPaginas(query);
      setPaginas(paginasResponse.data);
    } catch (error) {
      setError(true);
    }

    setLoading(false);
  };

  useEffect(() => {
    getSearchResults();
  }, [query]);

  const SearchResult = ({ data, type }) => {
    let url = type === "noticia" ? `noticias/${data.slug}` : data.url;

    return (
      <div className="mb-5 busqueda">
        <a href={url} target="_blank">
          <h2 className="title is-4">
            <Fa icon="link" />
            <u>{data.title}</u>
          </h2>
        </a>
        <br />
        <Markdown
          className="pagina-md preview has-text-grey-dark	"
          escapeHtml={false}
          source={truncateText(data.content)}
        />
        <hr />
      </div>
    );
  };

  const EmptyState = ({ list }) => {
    return list.length === 0 ? (
      <Message type="warning">
        No se pudieron recuperar resultados que cumplan los criterios de
        busqueda establecidos.
      </Message>
    ) : (
      ""
    );
  };

  return (
    <div className="container is-fluid is-pagina">
      <div className="columns">
        {loading && <Loading />}
        {!loading && !error && (
          <div className="column">
            <h1 className="title ">
              Resultados de la búsqueda: <i>"{filter.get("_q")}</i>"
            </h1>
            <hr />
            <a
              className={`search-title ${
                showing === "paginas" ? "active" : ""
              }`}
              onClick={() => setShowing("paginas")}
            >
              Paginas
            </a>
            <a
              className={`search-title ${
                showing === "noticias" ? "active" : ""
              }`}
              onClick={() => setShowing("noticias")}
            >
              Noticias
            </a>
            {showing === "paginas" && (
              <ul>
                <EmptyState list={paginas} />
                {paginas.map((pagina) => (
                  <SearchResult key={pagina.id} data={pagina} type="pagina" />
                ))}
              </ul>
            )}

            {showing === "noticias" && (
              <ul>
                <EmptyState list={noticias} />
                {noticias.map((noticia) => (
                  <div className="mb-5">
                    <SearchResult
                      key={noticia.id}
                      data={noticia}
                      type="noticia"
                    />
                  </div>
                ))}
              </ul>
            )}
          </div>
        )}
        {!loading && error && (
          <div className="column is-half mx-6">
            <br />
            <Error>
              No se pudieron recuperar los resultados de la búsqueda
            </Error>
          </div>
        )}
      </div>
    </div>
  );
}

export default Busqueda;
