import React from "react";

export function VideoCard({ selected, data }) {
  return (
    <React.Fragment>
      <article className={`media video`}>
        <figure className="media-left">
          <p className="image">
            {data.media && (
              <img
                className="has-background-light"
                loading="lazy"
                src={data.media.url}
              />
            )}
          </p>
        </figure>
        <div className="media-content">
          <div className="content">
            <p>
              <strong className="is-size-6 line-clamp-2 mb-1">
                {data.title}
              </strong>
              <span className="is-size-7 line-clamp-4">{data.content}</span>
            </p>
          </div>
        </div>
      </article>
    </React.Fragment>
  );
}
