import React from "react";
import { MediaObject } from "../../3party/Bulma";
import { Fa } from "../../3party/Fa";

export function ListaDocumentos({ data }) {
  return (
    <div className="column timeline-child">
      <div className="menu">
        {data.children.map((item) => {
          return (
            <>
              <ul class="menu-list">
                <li>
                  <a class="is-active">
                    <Fa icon="bookmark" />
                    {item.title}
                  </a>
                  {item.description && (
                    <div className="column is-12">
                      <p>{item.description}</p>
                    </div>
                  )}
                  <ul>
                    {item.children
                      .sort((a, b) => a.order - b.order)
                      .map((el) => {
                        return (
                          <li>
                            {!el.media && (
                              <a className={el.id}>
                                <Fa icon="unlink"></Fa> {el.title}
                              </a>
                            )}
                            {el.media && (
                              <a
                                className={el.id}
                                target="_blank"
                                href={`/cms/${el.media.url}`}
                              >
                                <Fa icon="download"></Fa> {el.title}
                                <br />
                                <p className="help">
                                  <i>{el.content}</i>
                                </p>
                              </a>
                            )}
                          </li>
                        );
                      })}
                  </ul>
                </li>
              </ul>
            </>
          );
        })}
      </div>
    </div>
  );
}
