import React, { useState, useEffect } from "react";

import { NewsBox } from "./NewsBox";
import { NewsCard } from "./NewsCard";
import strapiAPI from "../../../api/strapi-api";
import OwlCarousel from "react-owl-carousel2";
import { Loading } from "../loading/Loading";
import { Error } from "../error/Message";

export function NewsList(props) {
  const [destacado, setDestacado] = useState(null);
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);

  const options = {
    autoplay: true,
    items: 4,
    //loop: true,
    responsive: {
      0: {
        items: 1,
      },
      600: {
        items: 2,
      },
      900: {
        items: 3,
      },
      1300: {
        items: 4,
      },
      1600: {
        items: 5,
      },
    },
  };

  /**
   * Se encarga de recuperar la lista de noticias para renderizar.
   */
  const getNoticias = () => {
    setLoading(true);
    strapiAPI
      .listNoticias({_limit:8})
      .then((response) => {
        let destacadoIndex = response.data.findIndex(
          (post) => post.primary === true
        );

        destacadoIndex = destacadoIndex < 0 ? 0 : destacadoIndex;

        setDestacado(response.data[destacadoIndex]);
        response.data.splice(destacadoIndex, 1);

        setData(response.data);
      })
      .catch((err) => {
        setError(true);
      })
      .finally((resp) => {
        setLoading(false);
      });
  };

  /**
   * Se encaga de manejar la actualización del componente
   */
  useEffect(() => {
    getNoticias();
  }, []);

  /**
   * Renderiza el componente
   */
  return (
    <React.Fragment>
      <h3 className="title is-3">Noticias destacadas</h3>
      {loading && <Loading />}
      <div className="columns">
        {!loading && destacado && <NewsCard noticia={destacado}></NewsCard>}
      </div>
      {!loading && !error && (
        <div className="columns">
          <OwlCarousel options={options}>
            {data &&
              data.map(function (item) {
                return <NewsBox key={item.id} noticia={item}></NewsBox>;
              })}
          </OwlCarousel>
        </div>
      )}
      {!loading && error && (
        <Error>No se pudieron recuperar las noricias destacadas.</Error>
      )}
    </React.Fragment>
  );
}
