import React from "react";
import { Pagina } from "../../cms/Pagina";
import Form from '../form/Form';

const Solicitud = ( props ) => {
	return (
		<>
			<Pagina path="solicitud-de-informacion">            
				<Form type='solicitud' />
			</Pagina>
		</>
	);
}

export default Solicitud;
