import React, { useState, useEffect, useContext } from "react";
import strapiAPI from "../../../api/strapi-api";
import { useParams } from "react-router-dom";
import ReactMarkdown from "react-markdown";
import { Carousel } from "react-responsive-carousel";
import { ViewsContext } from "../../../context/ViewsContext";
import { Loading } from "../loading/Loading";
import { Error } from "../error/Message";

export function Noticia(props) {
  const { id } = useParams();
  const [data, setData] = useState({});
  const [singleImg, setSingleImg] = useState(false);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const { dispatch } = useContext(ViewsContext);

  /**
   * Se encaga de manejar la actualización del componente
   */
  useEffect(() => {
    getNoticia();
  }, []);

  /**
   * Se encarga de recuperar la lista de noticias para renderizar.
   */
  const getNoticia = () => {
    setLoading(true);
    strapiAPI
      .getNoticia(id)
      .then((response) => {
        let noticia = null;
        if (response.data.length != 0) {
          noticia = response.data[0];
          dispatch({
            type: "UPDATE_VIEWS",
            payload: noticia.view,
          });
        }
        setData(noticia);
        setSingleImg(noticia.media.length == 1);
      })
      .catch((err) => {
        setError(true);
      })
      .finally((resp) => {
        setLoading(false);
      });
  };

  /**
   * Renderiza el componente
   */
  return (
    <div className="container is-fluid pagina">
      {loading && <Loading />}
      {!loading && !error && (
        <div className="pagina-content">
          <h3 className="title is-3">{data.title}</h3>
          <p>{data.excerpt}</p>
          <Carousel
            showArrows={!singleImg}
            showStatus={!singleImg}
            showIndicators={!singleImg}
            showThumbs={false}
          >
            {data.media.map(function (item) {
              return (
                <img
                  className="has-background-light"
                  loading="lazy"
                  alt={item.caption}
                  src={`/cms/${item.url}`}
                />
              );
            })}
          </Carousel>
          <br />
          <ReactMarkdown
            className="pagina-md"
            escapeHtml={false}
            source={data.content}
          />
        </div>
      )}
      {!loading && error && (
        <Error>No se pudo recuperar la noticia de la base de datos.</Error>
      )}
    </div>
  );
}
