import React, { useEffect, useState } from "react";

const LogosOEE = ({ listas }) => {
  const [instituciones, setInstituciones] = useState([]);

  useEffect(() => {
    const target = listas.find(
      (item) => item.code === "instituciones-involucradas"
    );
    target && target.items.sort((a, b) => a.order - b.order);
    target && setInstituciones(target.items);
  });

  if (instituciones.length > 0)
    return (
      <div className="logos">
        {instituciones.map((section) => {
          return (
            <div>
              <h4 className="title is-6">{section.title}</h4>
              {section.children
                .sort((a, b) => a.order - b.order)
                .map((institucion) => (
                  <div key={institucion.id} className="logo">
                    <a href={institucion.url}>
                      <img
                        className="image"
                        src={`/cms/${institucion.media.url}`}
                      />
                    </a>
                  </div>
                ))}
            </div>
          );
        })}
      </div>
    );

  return <></>;
};

export default LogosOEE;
