import jQuery from "jquery";
//import  "owl.carousel";
import React, { useState, useEffect } from "react";
import { Album } from "./Album";

import flickrAPI from "../../../api/flickr-api";
import { Loading } from "../loading/Loading";
import { Error } from "../error/Message";

global.jQuery = jQuery;
global.jquery = jQuery;
global.$ = jQuery;

export function Albumes(props) {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const [data, setData] = useState([]);
  /**
   * Este metodo se encarga de recuperar la lista de albumes
   * de flickr para su renderizado.
   */
  const getAlbumes = () => {
    flickrAPI
      .albumes()
      .then((response) => {
        if (response.data.stat == "ok") {
          setData(response.data.photosets.photoset);
        } else {
          setError(true);
        }
      })
      .catch((error) => {
        setError(true);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    getAlbumes();
  }, []);

  return (
    <React.Fragment>
      <h3 className="title is-3">Galería de Imágenes</h3>
      <div className="columns is-multiline">
        {loading && <Loading />}
        {!loading &&
          !error &&
          data &&
          data.map(function (item) {
            return <Album key={item.id} data={item} />;
          })}
      </div>
      {error && (
          <Error>Ocurrió un error al recuperar los albumes del sitio web.</Error>
        )}
        
    </React.Fragment>
  );
}
