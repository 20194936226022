import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";

export function Graficos({ data }) {
  /**
   * Renderiza el componente
   */
  return (
    <React.Fragment>
      <div className="pagina-sections ">
        <div className="content">
          {data.map(function (list) {
            {
              return list.items.map(function (item) {
                return (
                  <div className="column is-12">
                    <div className="box">
                      <article className="media">
                        <div className="media-left">
                          <figure className="image is-512x512">
                            <img
                              className="has-background-light"
                              loading="lazy"
                              src={`${item.media ? item.media.url : ""}`}
                              alt={item.title}
                            />
                          </figure>
                        </div>
                        <div className="media-content">
                          <div className="content">
                            <p className="title is-4">{item.title}</p>
                            <p className="has-text-justified">{item.content}</p>
                          </div>

                          <Link
                            className="button is-oulined "
                            to={`/graficos/${item.id}`}
                          >
                            Ir a Gráfico
                          </Link>
                        </div>
                      </article>
                    </div>
                  </div>
                );
              });
            }
          })}
        </div>
      </div>
    </React.Fragment>
  );
}
