import React from "react";
import { Pagina } from "../../cms/Pagina";
import Form from '../form/Form';

export function Contacto(props) {
  return (
    <>   
        <Pagina path="contactos-y-consultas">
          <br />
          <h3>Formulario de contacto</h3>
          <br />
          <Form type="contacto" />
        </Pagina>
    </>
  );
}
