import React from 'react';
import { Pagina } from "../../cms/Pagina";

const ContactTable = ( props ) => {
    return (
    <div className="table-container">
      <table className="table is-hoverable is-bordered p-5">
        
          <tr>
            <th>
              <h4>COMITÉ EJECUTIVO TÉCNICO</h4>
              <p>STP: secretaría, representación legal</p>
            </th>
            <td>
              <p>Debería responder en 5 días hábiles</p>
              <p>Ministra Viviana Casco</p>
              <p>Secretaría Técnica de Planificación del Desarrollo Económico y Social</p>
              <p>Estrella 505 esq. 14 de Mayo, Asunción - Paraguay</p>
              <p><a href="mailto:sg-stp@stp.gov.py">sg-stp@stp.gov.py</a></p>
              <p>+595 21 450 422</p>
            </td>
          </tr>
          <tr>
            <th>
              <h4>COMITÉ DIRECTIVO</h4>
              <p>STP, INDI, INFONA, MAG, MADES, VMME, AFD, INDERT</p>
            </th>
            <td>
              <p>Cualquier organización podría recibir una queja y debe proporcionar la confirmación del recibo, invitando a la persona a 
                tener una reunión específica para documentar el caso. Si el caso es relevante, el receptor debe sentir la información a todos
                los miembros del comité de dirección y pedir una reunión para trabajar el problema. La respuesta debe enviarse a los próximos
                5 trabajando después de la reunión del comité de dirección.
              </p>
              <p>STP: <a href="https://www.stp.gov.py/v1">www.stp.gov.py/v1</a></p>
              <p>INDI: <a href="https://www.indi.gov.py/">www.indi.gov.py/</a></p>
              <p>INFONA: <a href="http://www.infona.gov.py/">www.infona.gov.py/</a></p>
              <p>MAG: <a href="http://www.mag.gov.py/">www.mag.gov.py/</a></p>
              <p>SAS: <a href="https://www.mds.gov.py/">www.mds.gov.py/</a></p>
              <p>SEAM: <a href="http://www.mades.gov.py/">www.mades.gov.py/</a></p>
              <p>VMME: <a href="https://www.ssme.gov.py/">www.ssme.gov.py/</a></p>
              <p>AFD: <a href="https://www.afd.gov.py/">www.afd.gov.py/</a></p>
              <p>INDERT: <a href="http://www.indert.gov.py/">www.indert.gov.py/</a></p>
            </td>
          </tr>
          <tr>
            <th>
              <h4>FAO</h4>
              <p>Representación</p>
            </th>
            <td>
              <p>Debe responder en 5 días hábiles en consulta con el STP.</p>
              <p>Jorge Meza</p>
              <p><a href="mailto:FAO-PY@FAO.ORG">FAO-PY@FAO.ORG</a></p>
              <p><a href="mailto:Jorge.Meza@FAO.ORG">Jorge.Meza@FAO.ORG</a></p>
            </td>
          </tr>
          <tr>
            <th>
              <h4>FAO Regional Office for Latin American and the Caribbean</h4>
            </th>
            <td>
              <p>Debe responder en 5 días hábiles en consulta con el representante de la FAO</p>
              <p>Julio Berdegué</p>
              <p><a href="mailto:Julio.Berdegue@FAO.ORG">Julio.Berdegue@FAO.ORG</a></p>
              <p><a href="mailto:RLC-ADG@FAO.ORG">RLC-ADG@FAO.ORG</a></p>
              <p>Phone: +56 2 2923 2251</p>
            </td>
          </tr>
          <tr>
            <th>
              <h4>Office of the Inspector-General (OIG)</h4>
            </th>
            <td>
              <p>Para denunciar posibles fraudes y conductas indebidas por fax confidencial</p>
              <p>By email: <a href="mailto:investigations-hotline@fao.org">investigations-hotline@fao.org</a></p>
              <p>By confidential hotline: (+39) 06 570 52333</p>
            </td>
          </tr>
      </table>
    </div>
    );
}

const Quejas = ( props ) => {
    return (
        <Pagina path="quejas-y-reclamos">
            <br /> <br />
            <ContactTable></ContactTable>
        </Pagina>
    );
}

export default Quejas;