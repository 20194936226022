import React from "react";

export function Message({ title, type, children }) {
  return (
    <article className={`message is-${type}`}>
      {title && (
        <div className="message-header">
          <p>{title}</p>
        </div>
      )}
      <div className="message-body">{children}</div>
    </article>
  );
}

export function Success({ title, children }) {
  return (
    <Message title={title} type="success">
      {children}
    </Message>
  );
}

export function Error({ title, children }) {
  return (
    <Message title={title} type="danger">
      {children}
    </Message>
  );
}

export function Warning({ title, children }) {
  return (
    <Message title={title} type="warning">
      {children}
    </Message>
  );
}
