import React, { useState } from "react";
import { Link } from "react-router-dom";

export const ListaPagina = ({ data }) => {
  const renderItem = ({ title, url, description }, style) => (
    <>
      {url && url.includes("http") && (
        <a className={style} href={url} title={description}>
          {title}
        </a>
      )}
      {url && !url.includes("http") && (
        <Link className={style} to={url} title={description}>
          {title}
        </Link>
      )}
      {url === "" && (
        <li className={style} title={description}>
          {title}
        </li>
      )}
    </>
  );

  const renderSection = (item) => {
    //if (item.children && !item.children.length) return renderItem(item, "");
    return (
      <>
        {renderItem(item, "navbar-link")}
        {item.children && item.children.length && (
          <ul className="menu-list">
            <li>{item.children.map((subitem) => renderSection(subitem))}</li>
          </ul>
        )}
      </>
    );
  };
  return (
    <aside class="menu">
      {data &&
        data &&
        data.items &&
        data.items.map((item) => renderSection(item))}
    </aside>
  );
};
