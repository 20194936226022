import React, { useEffect, useState } from "react";
import ReactMarkdown from "react-markdown";
import strapiAPI from "../../api/strapi-api";
import $ from "jquery";
import { useParams } from "react-router-dom";
import { Loading } from "../pagina/loading/Loading";
import { Error } from "../pagina/error/Message";


export function Grafico() {
  const { id } = useParams();
  const [data, setData] = useState({});
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);

  /**
   * Se encaga de manejar la actualización del componente
   */
   useEffect(() => {
    getData();
  }, []);

  /**
   * Se encarga de recuperar la lista de noticias para renderizar.
   */
  const getData = () => {
    setLoading(true);
    strapiAPI
      .getItem(id)
      .then((response) => {
        setData(response.data);
      })
      .catch((err) => {
        setError(true);
      })
      .finally((resp) => {
        setLoading(false);
      });
  };

  /**
   * Renderiza el componente
   */
   window.resizeIframe = function (obj) {
    // puede inr entre comas los valores de los items especiales
    var customSelectors = "#js-plotly-tester, body, #root,.scroll-y";
    // si ya existe un interval se elimina para evitar solapamiento
    if (window.iframepid) {
      clearInterval(window.iframepid);
    }
    if (obj == null || obj.contentWindow == null) {
      return;
    }
    //se añade la funcion de redimensionamiento
    window.iframepid = setInterval(() => {
      if (obj == null || obj.contentWindow == null) {
        return;
      }
      //se obtiene el tamaño maximo de la pantalla.
      var maxHei = window.screen.availHeight;
      //se recupera el tamaño de iframe
      let size = obj.contentWindow.document.documentElement.scrollHeight;
      // se verifica y se añade un minimo.
      let h = size < 400 && size ? 400 : size;
      //se recupera y verifica si existe el target especial
      var $target = obj.contentWindow.document.querySelectorAll(customSelectors);
      if ($target) {
        var maxTargetHei = 0;
        $target.forEach(function (e) {
          var curr = $(e).height();
          maxTargetHei = curr > maxTargetHei ? curr : maxTargetHei;
        });
        //se calcula el tamaño
        var th = maxTargetHei;
        h = h < th ? th : h;
      }
      //se verifica que no supuere el maximo de la pantalla.
      h = h >= maxHei ? h : maxHei;
      //se setea el tamaño final.
      obj.style.height = h + "px";
    }, 500);
  };


  return (
    <div className="container is-fluid pagina">
      {loading && <Loading />}
      {!loading && !error && (
        <>
          <h1 class="title">{data.title}</h1>
          <hr />
          <div className="pagina-content">
            <ReactMarkdown
             className="pagina-md"
              escapeHtml={false}
              source={`<iframe src="${data.url}" frameborder="0" scrolling="no" onload="resizeIframe(this)" />`}
            />
          </div>
        </>
      )}
      {!loading && error &&
      <Error>No se pudo obtener el gráfico de la base de datos.</Error>}
    </div>
  );
}
