import React, { useState, useEffect } from "react";
import { PhotoSwipeGallery } from "react-photoswipe";
import flickrAPI from "../../../api/flickr-api";
import { Foto } from "./Foto";
import { Loading } from "../loading/Loading";
import { Error } from "../error/Message";
import "react-photoswipe/lib/photoswipe.css";
import { Pagina } from "../../cms/Pagina";

export function Fotos(props) {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const [items, setItems] = useState({});
  const options = {
    bgOpacity: 0.8,
  };
  useEffect(() => {
    getFotos();
  }, []);

  /**
   * Se encarga de recuperar la lista de imagenes de un album de flickr.
   */
  const getFotos = () => {
    flickrAPI
      .fotos()
      .then((response) => {
        const mapping = [];
        response.data.photoset.photo.map((data) => {
          mapping.push({
            src: `https://live.staticflickr.com/${data.server}/${data.id}_${data.secret}_b.jpg`,
            thumbnail: `https://live.staticflickr.com/${data.server}/${data.id}_${data.secret}_w.jpg`,
            w: 1200,
            h: 900
          });
        });
        setItems(mapping);
      })
      .catch((error) => {
        setError(true);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const getThumbnailContent = (item) => {
    return <Foto data={item} />;
  };

  return (
    <Pagina path="galeria">
      {!loading && !error && items && (
        <PhotoSwipeGallery
          items={items}
          options={options}
          thumbnailContent={getThumbnailContent}
        />
      )}
      <div className="columns is-multiline">
        {loading && <Loading />}
        {error && (
          <Error
            message={`Ocurrió un error al recuperar las fotos del album.`}
          />
        )}
      </div>
      {!loading && !error && !items && <Error>{`El album no existe.`}</Error>}
      <br/>
    </Pagina>
  );
}
