import React, { useContext } from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import "./scss/App.scss";
import "@fortawesome/fontawesome-free/js/all";
import Header from "./components/navegation/Header";
import Navbar from "./components/navegation/Navbar";
import { Pagina } from "./components/cms/Pagina";
import Footer from "./components/navegation/Footer";
import { Home } from "./components/pagina/home/Home";
import { Galeria } from "./components/pagina/galeria/Galeria";
import { Fotos } from "./components/pagina/galeria/Fotos";
import { Noticias } from "./components/pagina/noticias/Noticias";
import { Noticia } from "./components/pagina/noticias/Noticia";
import { Documentos } from "./components/pagina/documentos/Documentos";
import { Staff } from "./components/pagina/staff/Staff";
import { Grafico } from "./components/cms/Grafico";
import { Contacto } from "./components/pagina/contacto/Contacto";
import Solicitud from "./components/pagina/solicitud/Solicitud";
import Quejas from "./components/pagina/quejas/Quejas";
import Busqueda from "./components/pagina/busqueda/Busqueda";
import { ViewsProvider } from "./context/ViewsContext";
import { ParametersProvider } from "./context/ParametersContext";
//import history from "./helper/history-helper";

function App() {
  return (
    <Router>
      <ViewsProvider>
        <ParametersProvider>
          <Header />
          <Navbar />
          <Switch>
            <Route exact path="/" component={Home} />
            <Route exact path="/busqueda" component={Busqueda} />
            <Route exact path="/contactos-y-consultas" component={Contacto} />
            <Route exact path="/quejas-y-reclamos" component={Quejas} />
            <Route exact path="/galeria" component={Fotos} />
            <Route exact path="/noticias" component={Noticias} />
            <Route exact path="/noticias/:id" component={Noticia} />
            <Route exact path="/graficos/:id" component={Grafico} />
            <Route exact path="/documentos-e-informes" component={Documentos} />
            <Route exact path="/staff-:codigo" component={Staff} />

            <Route exact path="/:codigo" component={Pagina} />
          </Switch>
          <Footer />
        </ParametersProvider>
      </ViewsProvider>
    </Router>
  );
}

export default App;
