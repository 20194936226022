import React from "react";

export const NotFound = props => {
    return (
        <div className="not-found has-text-centered">
            <p className="is-size-1">ERROR 404</p>
            <p className="is-size-3 mb-4">Página no encontrada</p>
            <p className="is-size-5">Lo sentimos, la página a la que intentaste acceder no existe</p>
        </div>
    );
}