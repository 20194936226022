import React from "react";

import { useHistory } from "react-router-dom";


export function Album({ data }) {
  const history = useHistory();
  const onClick = () => {
    history.push(`/galeria/${data.id}/fotos`);
  };

  const truncateText = (text) => {
    if (text.length > 50) {
      return text.substr(0, 47) + "...";
    }
    return text;
  };

  return (
    <div className="column is-3" onClick={(evt) => onClick()}>
      <div className="card galery">
        <div className="card-image">
          <figure className="image is-4by3">
            <img
              src={`https://live.staticflickr.com/${data.server}/${data.primary}_${data.secret}_w.jpg`}
              alt={data.title._content}
            />
          </figure>
        </div>
        <div className="card-content">
          <div className="media">
            <div className="media-content">
              <p className="title is-6">{truncateText(data.title._content)}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
