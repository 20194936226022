import React, { useState, useEffect, useContext } from "react";
import strapiAPI from "../../api/strapi-api";
import mapsImg from "../../images/map-location.jpg";
import { Fa } from "../3party/Fa";
import RedesSociales from "./RedesSociales";
import { ViewsContext } from "../../context/ViewsContext";

export default function Footer() {
  const [data, setData] = useState([]);
  const { state } = useContext(ViewsContext);

  const icons = {
    Dirección: "fa-map-marker",
    Teléfono: "fa-phone",
    "Correo electrónico": "fa-envelope",
  };

  useEffect(() => {
    strapiAPI
      .listFooter()
      .then((response) => {
        setData(response.data[0]);
      })
      .catch((error) => console.error(error.message));
  }, []);

  return (
    <React.Fragment>
      <br />
      <footer className="footer">
        <div className="content ">
          <div className="columns">
            <div className="column is-3">
              <h4>Redes Sociales</h4>

              <p className="is-size-4 social-media">
                {data &&
                  data.items &&
                  data.items[0].children.map((redSocial) => {
                    return (
                      <a
                        key={redSocial.id}
                        href={redSocial.url}
                        target="_blank"
                      >
                        <Fa icon={`${redSocial.title.toLowerCase()} fab`}></Fa>
                      </a>
                    );
                  })}
              </p>
              <div></div>
            </div>
            <div className="column ">&nbsp;</div>
            <div className="column is-7">
              <h4>Información</h4>
              <article className="media">
                <figure className="media-left">
                  <p className="image is-128x128">
                    <img
                      className="content-editor-image-left has-background-light"
                      loading="lazy"
                      height="89"
                      src={mapsImg}
                      width="113"
                    />
                  </p>
                </figure>
                <div className="media-content">
                  <div>
                    {data &&
                      data.items &&
                      data.items[1].children
                        .sort((a, b) => {
                          return a.order - b.order;
                        })
                        .map((info) => {
                          return (
                            <div key={info.id}>
                              <strong>
                                {icons[info.title] && (
                                  <span
                                    className={`fa ${icons[info.title]} fa-fw`}
                                  >
                                    &nbsp;
                                  </span>
                                )}
                                {info.title}:&nbsp;
                              </strong>
                              {info.content}
                            </div>
                          );
                        })}
                  </div>
                </div>
              </article>
            </div>
          </div>
        </div>
      </footer>
    </React.Fragment>
  );
}
