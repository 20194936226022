import React, { useEffect, useState } from "react";

import logo from "../../images/logo.png";
import gov from "../../images/gobierno-nacional.png";
import eslogan from "../../images/eslogan.png";
import strapiApi from "../../api/strapi-api";

export default function Header() {
  const [data, setData] = useState([]);

  useEffect(() => {
    strapiApi
      .listLogos()
      .then((response) => {
        setData(response.data[0]);
      })
      .catch((error) => console.error(error.message));
  }, []);

  return (
    <header>
      <div className="responsive-header">
        {data &&
          data.items &&
          data.items.map((item) => (
            <div className={item.content}>
              <img
                className="img-container"
                alt={item.title}
                src={item.media.url}
              />
            </div>
          ))}
      </div>
    </header>
  );
}
