import React, { useReducer, createContext } from 'react';

export const ViewsContext = createContext();

const initialState = {
  views: 0
};

const reducer = (state, action) => {
  if (action.type === 'UPDATE_VIEWS')
    return { ...state, views: action.payload };
  return state;
}

export const ViewsProvider = (props) => {
  const [state, dispatch] = useReducer(reducer, initialState);

  return (
    <ViewsContext.Provider value={{ state, dispatch }}>
      {props.children}
    </ViewsContext.Provider>
  );
}
