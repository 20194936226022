import React, { useState } from "react";
import { Link } from "react-router-dom";

export const MenuPagina = ({ menu }) => {
  const [openSidebar, setOpenSidebar] = useState(false);

  menu.items
    .sort((a, b) => a.order - b.order)
    .forEach((item) => {
      if (item.children && item.children.length) {
        item.children
          .sort((a, b) => a.order - b.order)
          .forEach((item) => {
            if (item.children && item.children.length) {
              item.children.sort((a, b) => a.order - b.order);
            }
          });
      }
    });

  const renderItem = ({ title, url, description }, style) => (
    <>
      {url && url.includes("http") && (
        <a className={style} href={url} title={description}>
          {title}
        </a>
      )}
      {url && !url.includes("http") && (
        <Link className={style} to={url} title={description}>
          {title}
        </Link>
      )}
      {url === "" && (
        <li className={style} title={description}>
          {title}
        </li>
      )}
    </>
  );
  return (
    <nav className="navbar is-transparent">
      <div className="navbar-brand">
        <a
          role="button"
          onClick={() => setOpenSidebar(!openSidebar)}
          className={`navbar-burger ${openSidebar ? "is-active" : ""}`}
          aria-label="menu"
          aria-expanded="false"
          data-target="navbarBasicExample"
        >
          <span aria-hidden="true"></span>
          <span aria-hidden="true"></span>
          <span aria-hidden="true"></span>
        </a>
      </div>
      <div
        id="navbarExampleTransparentExample"
        className={`navbar-menu ${openSidebar ? "is-active" : ""}`}
      >
        <div className="navbar-start">
          {menu &&
            menu &&
            menu.items &&
            menu.items.map((item) => {
              if (item.children && !item.children.length)
                return renderItem(item, "navbar-item");
              return (
                <div className="navbar-item has-dropdown is-hoverable">
                  {renderItem(item, "navbar-link")}
                  <div className="navbar-dropdown is-boxed">
                    {item.children.map((subitem) =>
                      renderItem(subitem, "navbar-item")
                    )}
                  </div>
                </div>
              );
            })}
        </div>
      </div>
    </nav>
  );
};
