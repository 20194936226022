import React from "react";
import Moment from "react-moment";

export function DateFormat({ children }) {
  var val = children;
  return <Moment format="YYYY/MM/DD">{val}</Moment>;
}

export function DateTimeFormat({ children }) {
  return <Moment format="YYYY/MM/DD HH:mm:ss">{children}</Moment>;
}
