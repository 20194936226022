import { Fa } from "./Fa";
import React, { useState, useEffect } from "react";

export function Collapsible({ title, children, className, open, onClick }) {
  const [collapse, setCollapse] = useState(open || false);

  const openPanel = () => {
    if (onClick) {
      onClick(!collapse ? "open" : "close");
    }
    setCollapse(!collapse);
  };

  const estado = collapse ? "open" : "close";
  return (
    <div className={`collapsible ${estado}`}>
      <div className={`collapsible-head`} onClick={openPanel}>
        {title} <Fa icon="angle-up icon" simple />{" "}
        <Fa icon="angle-down icon" simple />
      </div>
      <section className="collapsible-content">{children}</section>
    </div>
  );
}
