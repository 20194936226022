import React from "react";
import { Banner } from "../../cms/Banner";
import { NewsList } from "./NewsList";
import { Pagina } from "../../cms/Pagina";
import Logos from "../contacto/LogosOEE";
import { VideoBoxes } from "./VideoBoxes";

/**
 * Componente secundario que recibe los parametros del componente
 * padre de la página.
 *
 * @param {*} param
 */
function Content({
  pagina,
  banners,
  menus,
  documentos,
  videos,
  listas,
  otros,
}) {
  return (
    <React.Fragment>
      <div className="container is-fluid">
        <br />
        <Banner data={banners}></Banner>
        <br />
      </div>
      <br />
      <div className="container is-fluid ">
        <div className="columns is-desktop">
          <div className="column is-10-desktop is-12-tablet">
            <NewsList />
            <hr />
            <VideoBoxes data={videos}></VideoBoxes>
          </div>
          <div className="column is-2-desktop is-12-tablet">
            <Logos listas={listas} />
          </div>
        </div>
      </div>
      <br />
    </React.Fragment>
  );
}

/**
 * Main component
 * @param {*} props
 */
export function Home(props) {
  return (
    <Pagina path="home" content={false}>
      <Content></Content>
    </Pagina>
  );
}
