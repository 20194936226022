import React, { useState, useRef, useEffect, useContext } from "react";
import { Fa } from "../../3party/Fa";
import { FieldControl } from "../../3party/Bulma";
import strapiAPI from "../../../api/strapi-api";
import ReCAPTCHA from "react-google-recaptcha";
import { ParametersContext } from '../../../context/ParametersContext';
import { Error } from "../error/Message";
import { Success } from "../error/Message";


const Form = ({ type, listas }) => {

  const initialField = {
    value: '',
    filled: false,
    valid: false
  };

  const initialState = {
    nombre: {...initialField},
    apellido: {...initialField},
    mail: {...initialField},
    telefono: {...initialField},
    description: {...initialField},
    captcha: {...initialField}
  }

  const { getParameterByKey } = useContext(ParametersContext);
  const recaptchaSecret = getParameterByKey('RECAPTCHA_SECRET_FRONTEND');

  const [body, setBody] = useState(initialState);
  const [alert, setAlert] = useState(null);
  const [valid, setValid] = useState(false);
  const [sending, setSending] = useState(false);

  const recaptchaRef = useRef(null);

  useEffect(() => {
    let valid = true;
    for(let field in body) {
      valid = valid & body[field].valid
    }
    setValid(valid);
  },[body]);

  const handleChange = (field, newValue) => {
    let newBody = {
      ...body, 
      [field]:{
        ...body[field], 
        value: newValue, 
        filled: newValue !== ''
      }
    };
    validate(newBody, field, newValue);
  }

  const validate = (currentBody, field, value) => {

    let isValid,
        newBody,
        pattern = new RegExp(/^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/i);

    if(field === 'mail')
      isValid = pattern.test(value);
    else 
      isValid = value !== '';

    newBody = {...currentBody, [field]:{ ...currentBody[field], valid: isValid }}
    setBody(newBody);
  }

  const resetForm = () => {
    setBody(initialState);
  }

  const getSubmitFields = (currentBody) => {
    let submitBody = {};
    for(let field in currentBody) {
      submitBody = {...submitBody, [field]:currentBody[field].value}
    }
    return {...submitBody, tipo: type};
  }

  const handleSubmit = () => {

    if(!valid) return;

    setSending(true);

    let message;

    strapiAPI.postForm(getSubmitFields(body))
      .then(() => {
        message = {
          type: 'success',
          message: 'Su consulta ha sido enviada correctamente'
        };
      })
      .catch(error => {
        message = {
          type: 'danger',
          message: 'Ocurrió un error al enviar su consulta'
        };
      })
      .finally(() => {
        resetForm();
        recaptchaRef.current.reset();
        setSending(false);
        setAlert(message);
        setTimeout(() => setAlert(null), 3000);
      });
  }

  return (
    <>
      { recaptchaSecret &&
        <section className="columns">

        <div className="column is-6">
          <FieldControl type="text" label="Nombres" value={body.nombre.value} onChange={event => handleChange('nombre', event.target.value)} />
          <FieldControl type="text" label="Apellidos" value={body.apellido.value} onChange={event => handleChange('apellido', event.target.value)} />
          <FieldControl type="email" label="Correo Electrónico" value={body.mail.value} onChange={event => handleChange('mail', event.target.value)} 
          error={body.mail.value !== '' && !body.mail.valid} errorMessage="Por favor, ingrese un correo válido"/>
          <FieldControl type="text" label="Telefono" value={body.telefono.value} onChange={event => handleChange('telefono', event.target.value)} />
          <FieldControl type="textarea" label="Consulta" value={body.description.value} onChange={event => handleChange('description', event.target.value)} />
          
          <ReCAPTCHA
            sitekey={getParameterByKey('RECAPTCHA_SECRET_FRONTEND').value}
            ref={ recaptchaRef }
            onChange={() => handleChange('captcha', recaptchaRef.current.getValue())}
            onErrored={() => setBody({...body, captcha:{...body.captcha, valid: false}})}
          />

          {!sending &&
          <a className="button is-primary mt-2" disabled={!valid} onClick={handleSubmit}>
            <Fa icon="envelope" />
            Enviar
          </a>
          }
          {sending &&
          <div class="lds-dual-ring"></div>
          }
          <br /><br />
          {alert && alert.type === 'danger'  &&
            <Error> {alert.message} </Error>
          }
          {alert && alert.type === 'success'  &&
            <Success> {alert.message} </Success>
          }
        </div>
      </section>}
      { !recaptchaSecret &&
        <Error>No se pudo recuperar el parámetro del recaptcha para el formulario.</Error>}
    </>
  );
}

export default Form;