import React from "react";

export function Foto({ data }) {
  return (
    <div className="card">
      <div className="card-image">
        <figure className="">
          <img
            className="has-background-light"
            loading="lazy"
            src={data.thumbnail}
            alt={data.title}
          />
        </figure>
      </div>
    </div>
  );
}
