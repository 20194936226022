import React, { useState, useEffect } from "react";
import { useParams, Link } from "react-router-dom";

import { Card } from "../../3party/Bulma";
import { Pagina } from "../../cms/Pagina";
import {StringUtils} from "../../../common/StringUtils";
function Content({ listas }) {
  /**
   * renderiza el componente
   */
  return (
    <React.Fragment>
      <div className="container is-fluid">
        {listas.map((row) => {
          //return list.map((row) => {
          return (
            <React.Fragment>
              <h2 className="title is-4">{row.name}</h2>
              <div className="columns is-centered is-multiline">
                {row.items.map((el) => {
                  return (
                    <div className="column is-3 ">
                      <Card title={el.title} image={el.media.url}>
                        <div className="media">
                          <div className="media-content">
                            <p className="title is-6" style={{height: "35px"}}>{el.title}</p>
                          </div>
                        </div>
                        <hr />
                        <p style={{height: "52px"}} className="help">{el.content}</p>
                      </Card>
                    </div>
                  );
                })}
              </div>
            </React.Fragment>
          );
          // });
        })}
      </div>
    </React.Fragment>
  );
}

export function Staff(props) {
  const { codigo } = useParams();
  return (
    <Pagina path={`staff-${codigo}`}>
      <Content></Content>
    </Pagina>
  );
}
