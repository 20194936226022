import React, { useState, useEffect } from "react";
import strapiAPI from "../../../api/strapi-api";
import { Fa } from "../../3party/Fa";
import { Error } from "../error/Message";
import { NewsCard } from "../home/NewsCard";
import { Loading } from "../loading/Loading";

export function Noticias(props) {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const [page, setPage] = useState(0);
  const [lastPage, setLastPage] = useState(false);
  const maxItems = 15;
  const handleChange = (event, value) => {
    setPage(value);
  };

  /**
   * Se encarga de recuperar la lista de noticias para renderizar.
   */
  const getNoticias = () => {
    setLoading(true);
    strapiAPI
      .listNoticias("", {
        _limit: maxItems,
        _start: page  * maxItems,
      })
      .then((response) => {
        setData(response.data);
        setLastPage(response.data.length < maxItems);
      })
      .catch((err) => {
        setError(true);
      })
      .finally((resp) => {
        setLoading(false);
      });
  };

  /**
   * Se encaga de manejar la actualización del componente
   */
  useEffect(() => {
    getNoticias();
  }, [page]);

  /**
   * Renderiza el componente
   */
  return (
    <div className="container is-fluid pagina">
      <h3 className="title is-3">Noticias</h3>
      {loading && <Loading />}
      {!loading && !error && (
        <div className="columns is-multiline">
          {data.map(function (item) {
            return <NewsCard key={item.id} noticia={item}></NewsCard>;
          })}
        </div>
      )}
      {!loading && error && (
        <Error>No se pudieron recuperar las noticias.</Error>
      )}
      <hr/>
      <nav className="pagination" role="navigation" aria-label="pagination">
        <ul class="pagination-list"></ul>
        <a
          className="pagination-previous"
          disabled={page == 0}
          onClick={(evt) => {
            handleChange(evt, page - 1);
          }}
        >
          <Fa icon="chevron-left" /> Atras
        </a>
        <a
          className="pagination-next"
          disabled={lastPage}
          onClick={(evt) => {
            handleChange(evt, page + 1);
          }}
        >
          Siguiente&nbsp;<Fa icon="chevron-right" />
        </a>
      </nav>
    </div>
  );
}
