import axios from "axios";

/**
 * Helper class que tiene como objetivo agrupar los métodos para recuperar los
 * datos de CMS utilizados para dinamizar el portal.
 */
class StrapiAPI {
  /**
   * @constructor
   */
  constructor() {
    this.api = axios.create({
      baseURL: "/cms",
    });
  }

  /**
   * Se encarga de recuperar la lista de albumes de flickr
   */
  listNoticias(query, pag) {
    let args = { _sort: "primary:desc,date:desc", ...pag };

    if (typeof query === "string") {
      return this.api.get(`/posts${query}`, { params: args });
    } else {
      args = { ...args, ...query };
    }
    return this.api.get("/posts", { params: args });
  }

  /**
   * Se encarga de recuperar la lista de albumes de flickr
   */
  listDocumentos(query) {
    let args = { type: "Documentos", ...query };
    return this.api.get("/lists", { params: args });
  }

  /**
   * Se encarga de listar todo el menu
   */
  listMenu() {
    return this.api.get("/menu");
  }

  /**
   * Se encarga de listar todas las secciones
   */
  listSections() {
    return this.api.get("/sections");
  }

  /**
   * Se encarga de recuperar una noticia por su id
   */
  getNoticia(id) {
    let args = { slug: id };
    return this.api.get(`/posts/`, { params: args });
  }

  /**
   * Se encarga de recuperar un item por su id
   */
  getItem(id) {
    return this.api.get(`/items/${id}`);
  }

  /**
   * Se encarga de recuperar la lista de los menus.
   * @param {*} codigo
   */
  listMenus(codigo) {
    let args = { code: codigo, type: "Menus" };
    return this.api.get("/lists", { params: args });
  }

  /**
   * Se encarga de recuperar la lista de los banners.
   * @param {*} codigo
   */
  listBanners(codigo) {
    let args = { code: codigo, type: "Banners" };
    return this.api.get("/lists", { params: args });
  }

  /**
   * Se encarga de recuperar una página por su url.
   * @param {*} codigo
   */
  getPagina(codigo) {
    return this.api.get("/pages", { params: { url: `${codigo}` } });
  }

  /**
   * Se encarga de listar todas las paginas de acuerdo a un query param
   * @param {*} query
   * @returns lis de paginas que coinciden con la busqueda
   */
  listPaginas(query) {
    return this.api.get(`/pages${query}`);
  }

  /**
   * Se encarga de recuperar la lista de videos
   */
  listVideos() {
    let args = { type: "Videos" };
    return this.api.get("/lists", { params: args });
  }

  /**
   * Se encarga de almacenar los datos del formulario de contacto
   */
  postForm(body) {
    return this.api.post("/forms", body);
  }

  /**
   * Se encarga de listar toda la informacion del footer
   */
  listFooter() {
    let args = { code: "footer", type: "Listas" };
    return this.api.get("/lists", { params: args });
  }

  /**
   * Se encarga de listar toda la informacion del footer
   */
  listLogos() {
    let args = { code: "logos-institucionales", type: "Listas" };
    return this.api.get("/lists", { params: args });
  }

  /**
   * Se encarga de recuperar todos los parametros publicos
   */
  listParameters() {
    return this.api.get("/parameters");
  }

  /**
   * Se encarga de recuperar las instituciones involucradas
   */
  listInstituciones() {
    let args = { code: "instituciones-involucradas" };
    return this.api.get("/lists", { params: args });
  }
}

export default new StrapiAPI();
